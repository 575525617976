<template>
  <el-dialog
    v-if="open"
    :title="$t('counselingEvent.confirmBooking')"
    :visible="open"
    @update:visible="
      val => {
        $emit('update:open', val)
      }
    "
    :modal-append-to-body="false"
    custom-class="el-dialog--small e-confirm-booking-dialog"
  >
    <p class="e-confirm-booking-dialog__text" v-html="bookCounselingEvent" />
    <el-form
      id="confirm-booking-form"
      class="e-form mt-2"
      @submit.native="submit"
    >
      <el-form-item :label="$t('counselingEvent.channel.name')">
        <el-select
          v-model="channel"
          class="el-select--fullwidth"
          name="channel"
        >
          <el-option
            v-for="channel in counselingEvent.channels"
            :key="'channel-' + channel"
            :label="$t(`counselingEvent.channel.${channel}`)"
            :value="channel"
          />
        </el-select>
      </el-form-item>
      <textarea-item
        v-model="remark"
        :label="$t('counselingEvent.remark')"
        name="remark"
        @keyup.native.ctrl.enter="submit"
      />
    </el-form>
    <div slot="footer" class="dialog-footer">
      <cancel-button class="e-align--left" @click="closeDialog" />

      <el-button type="primary" @click="submit" form="confirm-booking-form">{{
        $t('counselingEvent.book')
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import CancelButton from '../../atoms/CancelButton/CancelButton.vue'
import TextareaItem from '../../atoms/TextareaItem/TextareaItem.vue'
export default {
  name: 'ConfirmBookingDialog',
  components: { CancelButton, TextareaItem },
  props: {
    counselingEvent: {
      type: Object
    },
    open: {
      type: Boolean
    }
  },
  data() {
    return {
      channel: null,
      remark: ''
    }
  },
  watch: {
    open(newVal) {
      if (
        newVal &&
        this.counselingEvent &&
        this.counselingEvent.channels.length === 1
      ) {
        this.channel = this.counselingEvent.channels[0]
      }
    }
  },
  computed: {
    ...mapGetters(['bookCounselingEvent'])
  },
  methods: {
    closeDialog() {
      this.$emit('update:open', false)
    },
    submit() {
      this.$emit('submit', {
        id: this.counselingEvent.id,
        channel: this.channel,
        remark: this.remark
      })
    }
  }
}
</script>
